import React from "react";
import { IRetailer } from "../../@types/globals";
import { getFormattedCurrency } from "../../util";
import { OutboundLink } from "gatsby-plugin-google-gtag";

interface Props {
  url: string;
  currency?: string;
  price?: number;
  retailers: IRetailer[];
}

export default function PriceCheapest(props: Props) {
  const { price, currency, retailers, url } = props;

  return (
    <OutboundLink href={url} className="cheapest-price" target="_blank">
      <h4>
        {getFormattedCurrency(currency)}
        {price && price.toFixed(2)}
      </h4>
      <div className="cheapest-retailers">
        {retailers && retailers.length > 0
          ? retailers.map(
              (r) =>
                r && (
                  <img
                    key={r.id}
                    src={r.logo.center}
                    className="retailer-logo"
                  />
                )
            )
          : null}
      </div>
    </OutboundLink>
  );
}
