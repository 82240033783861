import React from "react";
import className from "classnames";
import TextTruncate from "react-text-truncate";
import { getFormattedCurrency } from "../../util";

import { OutboundLink } from "gatsby-plugin-google-gtag";

interface Props {
  url: string;
  currency?: string;
  isHighest?: boolean;
  isLowest?: boolean;
  location?: string;
  price?: number;
}

export default function PriceCell(props: Props) {
  const { price, currency, location, isHighest, isLowest, url } = props;

  const containClass = className({
    "price-container": true,
    lowest: isLowest,
    highest: isHighest,
    noprice: price === undefined,
  });

  const checkLocation = () => {
    if (location.length > 12) {
      return <div className="tooltip">{location}</div>;
    }
    return null;
  };

  return (
    <div className={containClass}>
      {location && checkLocation()}

      {price !== undefined ? (
        <OutboundLink href={url} className="price-link" target="_blank">
          <div className="price">
            {getFormattedCurrency(currency)}
            {price.toFixed(2)}
          </div>
          {location && (
            <>
              <TextTruncate
                line={3}
                truncateText="..."
                text={location}
                className="location"
              />
            </>
            // <span data-tip={location} className="location">
            //   {location}
            // </span>
          )}
        </OutboundLink>
      ) : (
        <span className="no-price-text">Tiada Harga</span>
      )}
    </div>
  );
}
